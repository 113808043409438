import './App.css';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Login } from './components/login/Login';
import { CreateEvents } from './components/CreateEvents/CreateEvents';
import Sidebar  from './components/Sidebar/Sidebar';
import React from 'react';
import ViewEvents from './components/ViewEvents/ViewEvents';
import { EditEvents } from './components/EditEvents/EditEvents';
import Edit  from './components/EditEvents/Edit';

function App() {
  const navigate = useNavigate()
  const [flag , setFlag] = React.useState(0)
  console.log(localStorage.getItem("agent"))
  React.useEffect(()=>{
    if(localStorage.getItem("agent") === null){
      navigate("/")
    }
    else{
      setFlag(1)
      // navigate("/create-events")
    }
  },[])
  return (
    <>
    
      <Routes><>
       {flag ==0?  <Route exact path="/" element={<Login />} />:
       <>
       <Route path="/" element={<Sidebar/>}/>
        <Route path="/view-events" element={<ViewEvents/>}/>,
        <Route path="/create-events" element={<Sidebar />} />
        <Route path="/events/events-details/edit-events/:id" element={<Edit/>}/>
        </>}</>
      </Routes>
    </>
  );
}

export default App;
