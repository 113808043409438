import React, { useEffect, useState, useRef } from "react";
import "./createEvents.css";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Geocode from 'react-geocode'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const url = "https://enterbkapi.herokuapp.com/api/";
const url2 = "https://enterapi.herokuapp.com/api/upload";

export const CreateEvents = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [addInput, setAddInput] = useState([{ eventImg: null }]);
  const [load , setload] = useState(false)
  const [id , setid] = useState("")

  let q = [];

  const uploadImages = async (values,lat,lon) => {
   
    for (let i = 0; i < addInput.length; i++) {
      const data = new FormData();
      const filename = Date.now() + addInput[i].eventImg.name;
      data.append("name", filename);
      data.append("photo", addInput[i].eventImg);
      console.log(data)
     
      try {
        const res = await axios.post("https://enterbkapi.herokuapp.com/upload/image",data);
        console.log(res.data);
        q[i] = res.data.url
      } catch (err) {
        console.log(err);
      }
    }
    const result = await axios.post(url + "events", {
      title: values.title,
      category: values.category,
      contact_email: values.contact_email,
      subTitle: values.subTitle,
      venue: values.venue,
      address: values.address,
      lat: lat,
      lon:lon,
      start: values.start,
      end: values.end,
      price: values.price,
      date: values.date,
      place: values.place,
      img1: q[0],
      img2: q[1],
      img3: q[2],
      img4: q[3],
      img5: q[4],
      flag: 0,
      created_by: id.id,
      is_selectable:0
    });
    setload(true)
    if (result.data.status === true) {
      toast.success("Event Created Successfully");
      setload(false)
    } else {
      toast.error("Event Creation Failed");
    }
  };
  
 
  useEffect(() => {
    const fetchData = async () => {
      const a = JSON.parse(localStorage.getItem("agent"))
      setid(a)
      console.log(a.id)
      const result = await axios.get(url + "category");
      setCategoryData(result.data);
    };
    fetchData();
  }, []);

  const handleAddImg = () => {
    setAddInput([...addInput, { eventImg: null }]);
  };
  const handleRemoveImg = (ind) => {
    const list = [...addInput];
    list.splice(ind, 1);
    setAddInput(list);
  };
  const handleChangeImg = (e, ind) => {
    const list = [...addInput];
    list[ind].eventImg = e.target.files[0];
    setAddInput(list);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      category: "",
      subTitle: "",
      contact_email: "",
      venue: "",
      address: "",
      lat: "",
      lon: "",
      start: "",
      end: "",
      price: "",
      date: "",
      place: "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
      flag: 0,
      created_by: "",
    },
    onSubmit: (values) => {
      console.log(values);
      setload(true)
      Geocode.setApiKey("AIzaSyCqDXws4tnwb5zmd4xjlg9dj8W_lhe0XLU");
      Geocode.fromAddress(values.address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat,lng)
      uploadImages(values,lat,lng);
      // uploadImages(values,lat,lng);
        },
        (error) => {
          console.log(error)
          setload(false)
          toast.error("Something is wrong with your address. Please confirm if you added correct address");
        }
      );
    },
    validationSchema: yup.object({
      title: yup.string().required("Please Select Title."),
      category: yup.string().required("Category is required."),
      subTitle: yup.string().required("Subtitle is required."),
      contact_email: yup
        .string()
        .required("Email is required.")
        .email("Email is not valid."),
      venue: yup.string().required("Venue is required."),
      address: yup.string().required("Address is required."),
      lat: yup.string().required("Latitude is required."),
      lon: yup.string().required("Longitude is required."),
      start: yup.string().required("Please select start time"),
      end: yup.string().required("Please select end time"),
      price: yup.number().required("Price is required."),
      date: yup.date().required("Please select date."),
      place: yup.string().required("Place is required"),
    }),
  });
  return (
    <div className="container my-5">
      <div className="create-events-form">
        <p>Create Events</p>
      <ToastContainer />
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Title*</label>
            <input
              type="text"
              className="form-control"
              id="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              required
            />
            {formik.errors.title ? (
              <div className="error">{formik.errors.title}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Category*</label>
            <select
              className="form-select small-input"
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              id="category"
              required
            >
              <option value="">Select</option>
              {categoryData.map((item) => (
                <>
                  <option value={item.id}>{item.name}</option>
                </>
              ))}
            </select>
            {formik.errors.category ? (
              <div className="error">{formik.errors.category}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Subtitle*</label>
            <input
              type="text"
              className="form-control"
              id="subTitle"
              onChange={formik.handleChange}
              value={formik.values.subTitle}
              required
            />
            {formik.errors.subTitle ? (
              <div className="error">{formik.errors.subTitle}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Organizer Email*</label>
            <input
              type="text"
              className="form-control"
              id="contact_email"
              onChange={formik.handleChange}
              value={formik.values.contact_email}
              required
            />
            {formik.errors.contact_email ? (
              <div className="error">{formik.errors.contact_email}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Venue*</label>
            <input
              type="text"
              className="form-control"
              id="venue"
              onChange={formik.handleChange}
              value={formik.values.venue}
              required
            />
            {formik.errors.venue ? (
              <div className="error">{formik.errors.venue}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Address*</label>
            <input
              type="text"
              className="form-control"
              id="address"
              onChange={formik.handleChange}
              value={formik.values.address}
              required
            />
            {formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Latitude*</label>
            <input
              type="text"
              className="form-control"
              id="lat"
              onChange={formik.handleChange}
              value={formik.values.lat}
              required
            />
            {formik.errors.lat ? (
              <div className="error">{formik.errors.lat}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Longitude*</label>
            <input
              type="text"
              className="form-control"
              id="lon"
              onChange={formik.handleChange}
              value={formik.values.lon}
              required
            />
            {formik.errors.lon ? (
              <div className="error">{formik.errors.lon}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Start Time*</label>
            <input
              type="time"
              className="form-control small-input"
              id="start"
              onChange={formik.handleChange}
              value={formik.values.start.to}
              required
            />
            {formik.errors.start ? (
              <div className="error">{formik.errors.start}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">End Time*</label>
            <input
              type="time"
              className="form-control small-input"
              id="end"
              onChange={formik.handleChange}
              value={formik.values.end}
              required
            />
            {formik.errors.end ? (
              <div className="error">{formik.errors.end}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              price*
              <input
                type="number"
                className="form-control small-input"
                id="price"
                onChange={formik.handleChange}
                value={formik.values.price}
                required
              />
              {formik.errors.price ? (
                <div className="error">{formik.errors.price}</div>
              ) : (
                ""
              )}
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Date*
              <input
                type="date"
                className="form-control small-input"
                id="date"
                onChange={formik.handleChange}
                value={formik.values.date}
                required
              />
              {formik.errors.date ? (
                <div className="error">{formik.errors.date}</div>
              ) : (
                ""
              )}
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">Place*</label>
            <input
              type="text"
              className="form-control"
              id="place"
              onChange={formik.handleChange}
              value={formik.values.place}
              required
            />
            {formik.errors.place ? (
              <div className="error">{formik.errors.place}</div>
            ) : (
              ""
            )}
          </div>
          {addInput.map((addImg, ind) => (
            <>
              <div className="mb-3">
                <input
                  type="file"
                  onChange={(e) => handleChangeImg(e, ind)}
                  id="eventImg"
                  // value={addImg.eventImg}
                  required
                />
                {addInput.length > 1 && (
                  <button
                    type="button"
                    className="romove-btn"
                    onClick={() => {
                      handleRemoveImg(ind);
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
              <div className="mb-3 add-more-btn">
                {addInput.length - 1 === ind && addInput.length < 5 && (
                  <button type="submit" onClick={handleAddImg}>
                    Add more
                  </button>
                )}
              </div>
            </>
          ))}
         {load === false? <button type="submit" className="btn btn-primary form-submit">
            Submit
          </button>:"Please wait..."}
        </form>
      </div>
    </div>
  );
};
