// import React, { useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import "./ViewEvents.css";
// import axios from "axios";
// // import apiUrl from "../ApiUrl/ApiUrl";

// export const ViewEvents = () => {
//   const [notification, setNotification] = useState();
//   const [status, setStatus] = useState();
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate()
// const apiUrl = 'https://enterbkapi.herokuapp.com/api/'
//   const sendNotification = async () => {
//     const result = await axios.post(apiUrl + "push/push/notify", {
//       message: notification,
//     });
//     setStatus(result.data);
//     setLoading(false);
//   };

//   return (
//     <>
//       {/* send notification modal */}
//       <div
//         className="modal fade"
//         id="notificationModal"
//         tabindex="-1"
//         aria-labelledby="notificationModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="notificationModalLabel">
//                 Send Notification
//               </h5>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body px-4">
//               <div className="user-input">
//                 <input
//                   type="text"
//                   name="txtUpdatedValue"
//                   placeholder="Write Notification"
//                   onChange={(e) => setNotification(e.target.value)}
//                   required
//                 />
//               </div>
//             </div>
//             <div className="modal-footer">
//               {loading === true ? (
//                 <div class="spinner-grow text-primary" role="status">
//                   <span class="visually-hidden">Loading...</span>
//                 </div>
//               ) : (
//                 <p>
//                   {status && status.status === true
//                     ? "Notification Sent"
//                     : status && status.status === false
//                     ? "Notification not sent"
//                     : ""}
//                 </p>
//               )}
//               <button
//                 type="submit"
//                 className="btn btn-primary"
//                 onClick={() => {
//                   sendNotification();
//                   setLoading(true);
//                 }}
//               >
//                 Send
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* main div */}
//       <div className="ViewEvents-main">
//         <div className="ViewEvents-link">
//           <NavLink
//             to="/dashboard"
//             className={({ isActive }) => (isActive ? "active " : "")}
//           >
//             <button>Dashbaord</button>
//           </NavLink>
//           <NavLink
//             to="/users"
//             className={({ isActive }) => (isActive ? "active " : "")}
//           >
//             <button>Users</button>
//           </NavLink>
//           <NavLink
//             to="/events"
//             className={({ isActive }) => (isActive ? "active " : "")}
//           >
//             <button>Events</button>
//           </NavLink>
//           <NavLink
//             to="/booking"
//             className={({ isActive }) => (isActive ? "active " : "")}
//           >
//             <button>Booking</button>
//           </NavLink>
//           <NavLink
//             to="/app-users"
//             className={({ isActive }) => (isActive ? "active " : "")}
//           >
//             <button>App Users</button>
//           </NavLink>
//           <button
//             type="button"
//             data-bs-toggle="modal"
//             data-bs-target="#notificationModal"
//           >
//             Notification
//           </button>
//           <NavLink
//             to="/feedback"
//             className={({ isActive }) => (isActive ? "active " : "")}
//           >
//             <button>Feedback</button>
//           </NavLink>
//         </div>
//         <div className="ViewEvents-link logout">
//           <button
//           onClick={()=>{
//             localStorage.removeItem("serialized")
//             win.location.reload()
//             navigate("/")
//           }}
//           >Logout</button>
//         </div>
//       </div>
//     </>
//   );
// };
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import Views from './View';
import Logo from '../../images/beenter.png'

const drawerWidth = 220;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function ViewEvents(props) {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { win } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [time , setTime] = React.useState("")
  const [agent , setAgent] = React.useState([])
  const Listing =[{id:1,title:'Create Event', url:"/create-events"}, {id:2,title:'View Created Events', url:"/view-events"}]

React.useEffect(()=>{
  let a = JSON.parse(localStorage.getItem("agent"))
setAgent(a)
const current = new Date();

// console.log(a.name)
},[])

const handleLogout =()=>{
  localStorage.removeItem("agent")
  navigate("/")
  window.location.reload()
}
const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>




    <div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You Sure ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to logout ? Your shift for today will be considered as completed for          </Typography>
            <Button variant="contained" type="submit" onClick={handleLogout}>Yes</Button>
            <Button variant="contained" type="submit" onClick={handleClose} style={{marginLeft:20}}>No</Button>
        </Box>
      </Modal>
    </div>
 
      <Toolbar />
      <img src={Logo}  style={{width:'160px',marginBottom:'25%',height:'40px'}}/>
        <List>
        {Listing.map((text, index) => (
          <ListItem key={text.id} disablePadding>
            <ListItemButton onClick={()=>{
              navigate(`${text.url}`)
            }}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List>
        {['Logout'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton  onClick={handleOpen}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      
    </div>
  );

  const container = win !== undefined ? () => win().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor:"#fe8022"

        }}
      >
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
           Welcome {agent.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Typography paragraph>
         Welcome to the agent panel. You can now create new events for <strong>B-Enter</strong>. If you find any query feel free to contact admin. We will surely be adding the chat system feature for our agents very soon.
        </Typography>
        {/* <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography> */}
      <Views/>
      </Box>
    </Box>
  );
}

ViewEvents.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  win: PropTypes.func,
};

export default ViewEvents;