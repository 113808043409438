import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import "./view.css"
import { useNavigate } from 'react-router-dom';
import { Alert, FormControl, InputLabel, NativeSelect } from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';
const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
function Views() {
const url = "https://enterbkapi.herokuapp.com/";
const [ls , setls] = useState([])
const [fls , setfls] = useState([])
const navigate = useNavigate()
  const [userId, setUserId] = useState();
  const [agent , setAgents] = useState([])
const [category , seCategory] = useState([])
const [id , setId] = useState(0)
const [imgurl , setImgUrl] = useState("")
const [file, setFile] = useState(null)
const [show, setOpen] = React.useState(false);
const [reset, setreset] = useState(false)
const [change , setChange] = useState("")
const [fid , setfid] = useState(10)
const handleOpen = () => {
  setOpen(true)
console.log(images)
};
const handleClose = () => setOpen(false);
const [show1, setOpen1] = React.useState(false);
const handleOpen1 = () => setOpen1(true);
const handleClose1 = () => setOpen1(false);
const [images , setImages] = useState({
  img1:"",
  img2:"",
  img3:"",
  img4:"",
  img5:""
})
React.useEffect(async()=>{
    let ag =  JSON.parse(localStorage.getItem("agent"))
    const response = await axios.get(url+"api/events")
    const catg =  await axios.get(url+"api/category")
    seCategory(catg.data)
    console.log(ag)
    setAgents(ag)
    setFile(null)
    setls(response.data)
    setfls(response.data)
    const f = ls.filter(e=>e.created_by ==agent.id)
    console.log(f, typeof(toString(agent.id)),'f')

},[reset])
const removeData2 = async (id) => {
  const result = await axios.delete(url + `api/events/delete/${id}`);
  console.log(result.data);
  setreset(!reset)
};
const removeData = (id) => {
  // setls(ls.filter((item) => item.id !== id));
  removeData2(id);
};
const handleImageChange = async ()=>{
 
    const data = new FormData();
    data.append("photo", file);
   
    try {
      const res = await axios.post("https://enterbkapi.herokuapp.com/upload/image",data);
      console.log(res.data.url);
      const res2 =  await axios.put( url+"api/events/images/update/"+id, {
        "img1":change!="img1" ? images.img1:res.data.url,
      "img2": change!="img2" ? images.img2: res.data.url,
      "img3": change!="img3" ? images.img3: res.data.url,
      "img4": change!="img4" ? images.img4: res.data.url,
      "img5": change!="img5" ? images.img5:res.data.url
        
      })
      console.log(res2.data)
      setImgUrl("")
      setImages({
      "img1":change!="img1" ? images.img1:res.data.url,
      "img2": change!="img2" ? images.img2: res.data.url,
      "img3": change!="img3" ? images.img3: res.data.url,
      "img4": change!="img4" ? images.img4: res.data.url,
      "img5": change!="img5" ? images.img5:res.data.url
      })
      handleClose1()
      // window.location.reload(false)
      setreset(!reset)
    
    } catch (err) {
      console.log(err);
    }
  
 }






  return (<>
    <div>View {agent.name}</div>
    <div
        className="modal fade mod"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 mb-0"></div>
            <div className="modal-body">
              <div className="alert-img">
                {/* <Alert variant='danger'/> */}
{/* <HighlightOffOutlined/> */}
                {/* <img src={alertIcon} alt="alert" /> */}
              </div>
              <p className="h5 text-center">
                Are you Sure you want to delete Event..?
              </p>
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => removeData(userId)}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

    {/* {
            <div>
                <p>{e.title}</p>
            </div>
        ))
       
    } */}

<Modal show={show1} onHide={handleClose1}>
  <Modal.Header closeButton>
  </Modal.Header>
  <Modal.Body>
    <div className=''>
      <div className="writeImg">
      <img className='modalimg' src={imgurl}/>
      {file &&
      <img
      className='modalimg'
        src= {URL.createObjectURL(file)}
        alt=""
      />}
      </div>
      <label>click below if you wish to change the image</label>
      <input type='file' onChange={e=> setFile(e.target.files[0])}/>
    </div>
  </Modal.Body>
  <Modal.Footer>
    {file ? <button className='btn btn-primary' onClick={()=>{
      handleImageChange()
    }}>Change</button>:""}
    <button onClick={()=>{setFile(null)
  handleClose1()}} className='btn btn-danger'>Cancel</button>
  </Modal.Footer>
</Modal>
<Modal show={show} onHide={handleClose} className="mod">
        <Modal.Header closeButton>
          <Modal.Title>Detailed Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <>
          {ls.filter(e=>e.id === id).map(e=>(
            <>
            <div className='div'>
             <div className="">
              <div>
                <strong>Title</strong>
             <p>{e.title}</p>
             </div>
             <div>
             <strong>Sub-title</strong>
             <p>{e.subTitle}</p>
             </div>
             <div>
             <strong>Venue</strong>
             <p>{e.venue}</p>
             </div>
             <div>
                <strong>Date</strong>
             <p>{e.date}</p>
             </div>
             <div>
             <strong>Place</strong>
             <p>{e.place}</p>
             </div>
             </div>
              <div className="">
              <div>
                <strong>Address</strong>
             <p>{e.address}</p>
             </div>
             <div>
             <strong>Start</strong>
             <p>{e.start}</p>
             </div>
             <div>
             <strong>End</strong>
             <p>{e.end}</p>
             </div>
             <div>
             <strong>Category</strong>
             <p>{e.categories[0] && e.categories[0].name}</p>
             </div>
             <div>
             <strong>Organizer Email</strong>
             <p>{e.contact_email}</p>
             </div>
             
             </div>
             </div>
             {/* <div className="">
             
             <div>
            
             </div> */}
             <h4>Images</h4>
             <div className="div">
           <img onClick={()=>{
 setImgUrl(e.images[0] && e.images[0].img1)
 setChange("img1")
            handleOpen1()
           }} className='img' src={e.images[0] && e.images[0].img1}/>
           <img onClick={()=>{
 setImgUrl(e.images[0] && e.images[0].img2)
 setChange("img2")
            handleOpen1()
           }} className='img' src={e.images[0] && e.images[0].img2}/>
           <img onClick={()=>{
 setImgUrl(e.images[0] && e.images[0].img3)
 setChange("img3")

            handleOpen1()
           }} className='img' src={e.images[0] && e.images[0].img3}/>
           <img onClick={()=>{
 setImgUrl(e.images[0] && e.images[0].img4)
 setChange("img4")
            handleOpen1()
           }} className='img' src={e.images[0] && e.images[0].img4}/>
           <img onClick={()=>{
 setImgUrl(e.images[0] && e.images[0].img5)
 setChange("img5")

            handleOpen1()
           }} className='img' src={e.images[0] && e.images[0].img5}/>
             </div>

             </>
          ))  
       
        }</>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>
            {
              handleClose()}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <FormControl >
  <InputLabel variant="standard" htmlFor="uncontrolled-native">
    Filter By Category
  </InputLabel>
  <NativeSelect
    defaultValue={10}
    inputProps={{
      name: 'Filter By Category',
      id: 'uncontrolled-native',
    }}
    onChange={(e)=>{setfid(e.target.value)
      if(e.target.value == 10){
        setfls([...ls])
        console.log(fls)
      }
      else{
    let aaa =ls.filter(s=> s.category === parseInt(e.target.value) )
    console.log(aaa , typeof(e.target.value),"aaa")
    setfls(aaa)}
    }}
  >
    <option value={10}>All</option>
   {
    category.map((e)=>
    <option value={e.id}>{e.name}</option>
   )}
  </NativeSelect>
</FormControl>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="right">Category</TableCell>
            <TableCell align="right">Contact Email</TableCell>
            <TableCell align="right">Place</TableCell>
            <TableCell align="right">Venue</TableCell>
            <TableCell align="right">ColorCode</TableCell>
            <TableCell align="right">Created At</TableCell>
            <TableCell align="right">Call to Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
           ls.filter(e=> e.created_by === (agent.id)).length===0?"No event created by you yet. Create an event and start working":
           fls.filter(e=> e.created_by === (agent.id)).map((row)=>(
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">{row.categories[0] && row.categories[0].name}</TableCell>
              <TableCell align="right">{row.contact_email}</TableCell>
              <TableCell align="right">{row.place}</TableCell>
              <TableCell align="right">{row.venue}</TableCell>
              <TableCell align="right">{row.categories[0] && row.categories[0].colorcode}</TableCell>
              <TableCell align="right">{row.created_at.split("T")[0]} {row.created_at.split("T")[1].split(".")[0]}</TableCell>
              <TableCell align="right">
                 <div className="col">
                  <div className="events-btn">
                    <button
                      onClick={() =>
                        navigate(
                          `/events/events-details/edit-events/${btoa(row.id)}`
                        )
                      }
                    >
                     <ModeEditOutlineOutlinedIcon/>
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      onClick={() => setUserId(row.id)}
                    >
                     <DeleteSweepOutlinedIcon/>
                    </button>
                    <button  onClick={()=>{
                setId(row.id)
                setImages({img1:row.images[0] && row.images[0].img1,img2:row.images[0] && row.images[0].img2, img3:row.images[0] &&row.images[0].img3 , img4:row.images[0]  && row.images[0].img4 , img5:row.images[0]&&row.images[0].img5})
               console.log(images)
               handleOpen()}}>
                    <RemoveRedEyeIcon/>

                    </button>
                  </div>
                </div>
               </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

export default Views